<template>
  <div>
    <div
      v-if="!hasData"
      class="noDataDonut"
    >
      <img src="../../assets/no-data-img.png">
    </div>

    <apexchart
      v-else
      type="donut"
      :options="chartOptions"
      :series="series"
      :height="height"
      :width="width"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    height: {
      type: [String, Number],
      default() {
        return 400;
      },
    },
    width: {
      type: [String, Number],
      default() {
        return 500;
      },
    },
    data: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    titles: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      series: [],  // Data for the donut chart
      chartOptions: {
        chart: {
          type: 'donut',
          width: '100%',
          height: '100%',
        },
        labels: [],  // Labels for the donut chart
        colors: ['#13DEB9', '#FFAE20', '#FA896B', '#5D87FF'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
        },
        legend: {
          show: false,
        }
      },
    };
  },
  computed: {
    hasData() {
      return this.series.some((value) => value > 0);
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.series = this.titles
          .filter((key) => key !== 'total_count')
          .map((key) => parseFloat(newData[key]) || 0);
      },
    },
    titles: {
      immediate: true,
      handler(newTitles) {
        this.chartOptions.labels = newTitles
          .filter((title) => title !== 'total_count')
          .map((title) => {
            // Replace underscores with spaces and convert to title case
            const formattedTitle = title
              .replace(/_/g, ' ')
              .toLowerCase()
              .replace(/\b\w/g, (match) => match.toUpperCase());

            // Check if the title is specifically "Inactive Vehicle"
            return formattedTitle === "In Active Vehicles" ? "Inactive Vehicles" : formattedTitle;
          });
      },
    },
  },
};
</script>
